import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ForgetType, InfoSeminar,  loginType } from '../models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { STATE_TOKEN } from '@sod/store/app.state.model';
import { Store } from '@ngxs/store';
import { Response } from '@sod/core/index';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private client: HttpClient, private store: Store) { }
  public login(data: loginType): Observable<number> {
    const userName = btoa(data.username);
    const password = btoa(data.password);
    return this.client.post<number>('api/Authentication/login', { userName, password });
  }
  public logout(): Observable<any> {
    return this.client.post<string>('api/Authentication/logout', {});
  }
  public getProfile(): Observable<any> {
    const store = this.store.selectSnapshot(STATE_TOKEN);
    return this.client.get<Response<any>>(`api/User/${store.userId}`);
  }


  public forgotPassword(email: string): Observable<boolean> {
    const headers = new HttpHeaders ({'accept': '*/*' , 'Content-Type':'application/json-patch+json'})
    return this.client.post<boolean>('api/Authentication/forgotPassword', `"${email}"`, { headers: headers });
  }
  public resetPassword(data: ForgetType): Observable<boolean> {
    const headers = new HttpHeaders ({'accept': '*/*' , 'Content-Type':'application/json-patch+json'})
    return this.client.put<boolean>('api/Authentication/changePassword', data,{ headers: headers });
  }
  public changePassword(data: ForgetType): Observable<boolean> {
    const headers = new HttpHeaders ({'accept': '*/*' , 'Content-Type':'application/json-patch+json'})
    const store = this.store.selectSnapshot(STATE_TOKEN);
    return this.client.put<boolean>(`api/User/${store.userId}/ChangePassword`, data,{ headers: headers });
  }
  public sendOtp(userId: number): Observable<boolean> {
    return this.client.post<boolean>(`api/Authentication/otp?userId=${userId}`, {});
  }
  public validOtp(otp: string, userId: number): Observable<{ token: string, userId: number, isAdmin: boolean }> {
    return this.client.post<{ token: string, userId: number, isAdmin: boolean }>(`api/Authentication/otp/valid`,{otp, userId});
  }
}
